.footer__guarantee-list {
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 1020px) {
    .footer__guarantee-list {
      justify-content: flex-end; } }

.footer__guarantee-img {
  display: block; }
  .footer__guarantee-img:not(:first-child) {
    margin-left: 15px; }
  .footer__guarantee-img.paypal {
    width: 49px;
    height: 49px; }
  .footer__guarantee-img.guarantee {
    width: 65px;
    height: 47px; }
  .footer__guarantee-img.mccafee {
    width: 103px;
    height: 38px; }

.footer__payment-list {
  display: inline-grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 15px 13px;
  margin-top: 32px; }

.footer__payment-img {
  display: block;
  width: 54px;
  height: 35px; }

.footer-nav {
  text-align: left; }
  @media only screen and (max-width: 1019px) {
    .footer-nav {
      column-count: 2; } }
  @media only screen and (min-width: 1020px) {
    .footer-nav {
      column-count: 4; } }

.footer-nav-link {
  display: block;
  white-space: nowrap; }
  @media only screen and (max-width: 1019px) {
    .footer-nav-link {
      text-align: left; } }
  @media only screen and (max-width: 1019px) {
    .footer-nav-link.break-after-mobile {
      break-after: column; } }
  @media only screen and (min-width: 1020px) {
    .footer-nav-link.break-after-desktop {
      break-after: column; } }

.footer-main {
  background-color: #1c2036;
  position: relative;
  overflow: hidden;
  font-family: "Roboto", sans-serif; }
  @media only screen and (max-width: 1019px) {
    .footer-main {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .footer-main {
      min-width: 985px; } }
  .footer-main .holder {
    position: relative; }
    @media only screen and (max-width: 1019px) {
      .footer-main .holder {
        padding: 40px 20px 29px;
        text-align: center; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .holder {
        padding-top: 40px;
        padding-bottom: 32px;
        display: flex; } }
  .footer-main .footer-logo {
    width: 60px;
    height: 23px; }
  .footer-main .footer-logo-au {
    width: 60px;
    height: 23px; }
  @media only screen and (min-width: 1020px) {
    .footer-main .middle-part {
      margin-left: 34px; } }
  .footer-main .footer-content1 {
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-content1 {
        font-size: 14px;
        line-height: 1.39;
        margin-top: 30px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer-content1 {
        font-size: 13px; } }
  .footer-main .footer-content2 {
    letter-spacing: 0.2px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-content2 {
        font-size: 14px;
        line-height: 1.5;
        margin: 12px 20px 24px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer-content2 {
        font-size: 13px;
        margin-top: 10px; } }
  .footer-main .footer-content-link {
    text-decoration: none;
    font-weight: bold;
    color: #f7f8fa; }
  .footer-main .footer-address {
    letter-spacing: 0.2px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-address {
        font-size: 14px;
        line-height: 1.64;
        letter-spacing: 0.2px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer-address {
        font-size: 12px;
        line-height: 2;
        margin-top: 20px; } }
  .footer-main .footer-address-title {
    font-weight: bold; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-address-title {
        display: block;
        margin-top: 22px; } }
  @media only screen and (max-width: 1019px) {
    .footer-main .footer-nav {
      margin: 32px 0 0 46px; } }
  @media only screen and (min-width: 1020px) {
    .footer-main .footer-nav {
      margin-top: 28px; } }
  @media only screen and (min-width: 1020px) {
    .footer-main .footer-nav.type2 {
      column-count: 3; } }
  .footer-main .footer-nav-link {
    font-size: 13px;
    line-height: 1.92;
    letter-spacing: 0.2px;
    color: #24a652; }
  .footer-main .footer-social {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-social {
        bottom: 32px;
        right: 26px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer-social {
        top: 40px;
        right: 0; } }
    .footer-main .footer-social li:not(:first-child) {
      margin-left: 15px; }
  .footer-main .twitter-icon {
    width: 23px;
    height: 18px; }
  .footer-main .fb-icon {
    width: 11px;
    height: 22px; }
  @media only screen and (min-width: 1020px) {
    .footer-main .footer-shape1 {
      position: absolute;
      top: 0;
      right: 0;
      width: 454px;
      height: 153px; } }
  @media only screen and (max-width: 1019px) {
    .footer-main .footer-shape1-mob {
      position: absolute;
      top: 0;
      right: 0;
      width: 154px;
      height: 58px; } }
  @media only screen and (min-width: 1020px) {
    .footer-main .footer-shape2 {
      position: absolute;
      width: 575px;
      height: 162px;
      bottom: 0;
      left: 0; } }
  @media only screen and (max-width: 1019px) {
    .footer-main .footer-shape2-mob {
      position: absolute;
      width: 375px;
      height: 179px;
      bottom: 0;
      left: 0; } }
  .footer-main .footer__labels-block {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer__labels-block {
        align-items: center;
        margin-top: 40px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer__labels-block {
        align-items: flex-end;
        position: absolute;
        top: 93px;
        right: 0; } }
  .footer-main .footer-copyright {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.2px;
    color: #f7f8fa;
    opacity: 0.5; }
    @media only screen and (max-width: 1019px) {
      .footer-main .footer-copyright {
        margin-top: 28px; } }
    @media only screen and (min-width: 1020px) {
      .footer-main .footer-copyright {
        margin-top: 25px; } }

.footer-type2 {
  background-color: #1c2036;
  position: relative;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  /*
  .payment-list {
    display: flex;
    flex-wrap: wrap;
    @include respond-to(handhelds) {
      justify-content: center;
      align-items: flex-start;
      margin-top: 16px;
    }
    @include respond-to(wide-screens) {
      justify-content: flex-end;
      width: 240px ;//300px;
      position: absolute;
      right: 0;
      top: 153px;
    }
  }
  .payment-item {
    @include respond-to(handhelds) {
      margin: 8px;
    }
    @include respond-to(wide-screens) {
      margin: 14px 0 0 16px;
    }
  }
  .payment-img {
    display: block;
    width: 54px;
    height: 35px;
  }
*/ }
  @media only screen and (max-width: 1019px) {
    .footer-type2 {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 {
      min-width: 985px; } }
  .footer-type2 .holder {
    position: relative; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .holder {
        padding: 40px 20px;
        text-align: center; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .holder {
        padding-top: 40px;
        padding-bottom: 32px;
        display: flex; } }
  .footer-type2 .footer-logo {
    width: 60px;
    height: 23px; }
  .footer-type2 .footer-logo-au {
    width: 60px;
    height: 23px; }
  .footer-type2 .footer-logo-thenerdify-invert {
    width: 60px;
    height: 23px; }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .middle-part {
      margin-left: 34px; } }
  @media only screen and (max-width: 1019px) {
    .footer-type2 .footer-nav {
      margin: 39px 0 32px 46px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .footer-nav {
      margin-top: 56px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .footer-nav.type2 {
      column-count: 3; } }
  .footer-type2 .footer-nav-link {
    font-size: 13px;
    line-height: 1.92;
    letter-spacing: 0.2px;
    color: #24a652; }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .bottom-part {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 32px; } }
  .footer-type2 .bottom-title {
    color: #f7f8fa;
    font-weight: bold; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .bottom-title {
        font-size: 14px;
        line-height: 1.64;
        letter-spacing: 0.21px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .bottom-title {
        font-size: 12px;
        line-height: 2;
        letter-spacing: 0.18px;
        margin-bottom: 4px; } }
  .footer-type2 .footer-address-line {
    letter-spacing: 0.2px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-address-line {
        font-size: 14px;
        line-height: 1.64;
        letter-spacing: 0.2px;
        margin-top: 16px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .footer-address-line {
        min-width: 366px;
        font-size: 12px;
        line-height: 1.67;
        letter-spacing: 0.18px; } }
  .footer-type2 .footer-address-title {
    font-weight: bold; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-address-title {
        display: block;
        margin-top: 22px; } }
  @media only screen and (max-width: 1019px) {
    .footer-type2 .footer-contacts {
      margin-top: 40px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .footer-contacts {
      margin-left: 50px; } }
  .footer-type2 .footer-content-link {
    text-decoration: none;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.18px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-content-link {
        display: inline-block;
        margin-top: 5px; } }
  .footer-type2 .footer-copyright {
    font-weight: bold;
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.18px;
    color: #f7f8fa;
    opacity: 0.5; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-copyright {
        text-align: center;
        margin-top: 33px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .footer-copyright {
        position: absolute;
        bottom: 34px;
        right: 0; } }
  .footer-type2 .footer__labels-block {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer__labels-block {
        align-items: center;
        margin-top: 40px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .footer__labels-block {
        align-items: flex-end;
        position: absolute;
        top: 93px;
        right: 0; } }
  .footer-type2 .footer-content1 {
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-content1 {
        font-size: 14px;
        line-height: 1.39;
        margin-top: 30px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .footer-content1 {
        font-size: 13px; } }
  .footer-type2 .footer-content2 {
    letter-spacing: 0.2px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer-type2 .footer-content2 {
        font-size: 14px;
        line-height: 1.5;
        margin: 12px 20px 24px; } }
    @media only screen and (min-width: 1020px) {
      .footer-type2 .footer-content2 {
        font-size: 13px;
        margin-top: 10px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .footer-shape1 {
      position: absolute;
      top: 0;
      right: 0;
      width: 454px;
      height: 153px; } }
  @media only screen and (max-width: 1019px) {
    .footer-type2 .footer-shape1-mob {
      position: absolute;
      top: 0;
      right: 0;
      width: 154px;
      height: 58px; } }
  @media only screen and (min-width: 1020px) {
    .footer-type2 .footer-shape2 {
      position: absolute;
      width: 575px;
      height: 162px;
      bottom: 0;
      left: 0; } }
  @media only screen and (max-width: 1019px) {
    .footer-type2 .footer-shape2-mob {
      position: absolute;
      width: 375px;
      height: 179px;
      bottom: 0;
      left: 0; } }

.footer_type3 {
  background-color: #023135;
  font-family: 'Axiforma',sans-serif;
  position: relative;
  overflow: hidden;
  /*
  .payment-list {
    display: flex;
    flex-wrap: wrap;
    @include respond-to(handhelds) {
      justify-content: center;
      align-items: flex-start;
      width: 250px;
      margin: 16px auto 0;
      order: 1;
    }
    @include respond-to(wide-screens) {
      justify-content: flex-end;
      width: 250px;
      position: absolute;
      right: 0;
      top: 153px;
    }
  }
  .payment-item {
    @include respond-to(handhelds) {
      margin: 8px;
    }
    @include respond-to(wide-screens) {
      margin: 14px 0 0 16px;
    }
  }
  .payment-img {
    display: block;
    width: 54px;
    height: 35px;
  }
*/ }
  @media only screen and (max-width: 1019px) {
    .footer_type3 {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 {
      min-width: 985px; } }
  .footer_type3 .holder {
    position: relative; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .holder {
        padding: 40px 20px;
        text-align: center; } }
    @media only screen and (min-width: 1020px) {
      .footer_type3 .holder {
        padding-top: 40px;
        padding-bottom: 20px;
        display: flex; } }
  .footer_type3 .logo-icon {
    display: block;
    width: 60px;
    height: 20px;
    fill: #fff; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .logo-icon {
        margin: 0 auto; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .middle-part {
      display: flex;
      flex-direction: column; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 .middle-part {
      margin-left: 25px; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .footer-nav {
      margin: 32px 0 0 46px;
      order: 1; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 .footer-nav {
      margin-top: 32px; } }
  .footer_type3 .footer-nav-link {
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 2.08;
    color: #007a5c; }
  .footer_type3 .bottom-title {
    color: #f7f8fa;
    font-weight: bold; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .bottom-title {
        font-size: 14px;
        line-height: 1.64;
        letter-spacing: 0.21px; } }
    @media only screen and (min-width: 1020px) {
      .footer_type3 .bottom-title {
        font-size: 12px;
        line-height: 2;
        letter-spacing: 0.18px;
        margin-bottom: 4px; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .bottom-address-title {
      display: none; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 .footer-address-block {
      margin-top: 35px; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .footer-address-line {
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 .footer-address-line {
      opacity: 0.5;
      font-size: 12px;
      line-height: 1.58;
      letter-spacing: 0.18px;
      color: #f7f8fa; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .footer-address-title {
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 2;
      letter-spacing: 0.18px;
      color: #f7f8fa;
      margin-top: 28px; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .footer-address {
      display: block;
      font-size: 12px;
      line-height: 2;
      letter-spacing: 0.18px;
      opacity: 0.5;
      color: #f7f8fa; } }
  @media only screen and (max-width: 1019px) {
    .footer_type3 .footer-contacts {
      margin-top: 40px;
      order: 1; } }
  @media only screen and (min-width: 1020px) {
    .footer_type3 .footer-contacts {
      position: absolute;
      bottom: 40px;
      right: 330px; } }
  .footer_type3 .footer-contact-link {
    display: block;
    text-decoration: none;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.18px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .footer-contact-link {
        display: inline-block;
        margin-top: 5px; } }
  .footer_type3 .footer-content1 {
    font-size: 13px;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .footer-content1 {
        line-height: 1.39;
        margin-top: 30px; } }
  .footer_type3 .footer-content2 {
    font-size: 13px;
    letter-spacing: 0.2px;
    opacity: 0.5;
    color: #f7f8fa; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .footer-content2 {
        line-height: 1.5;
        letter-spacing: 0.18px;
        margin-top: 12px; } }
    @media only screen and (min-width: 1020px) {
      .footer_type3 .footer-content2 {
        margin-top: 8px; } }
  .footer_type3 .footer-copyright {
    font-size: 12px;
    line-height: 2;
    letter-spacing: 0.18px;
    color: #f7f8fa;
    opacity: 0.5; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .footer-copyright {
        text-align: center;
        margin-top: 28px;
        order: 1; } }
    @media only screen and (min-width: 1020px) {
      .footer_type3 .footer-copyright {
        position: absolute;
        bottom: 20px;
        right: 0; } }
  .footer_type3 .footer__labels-block {
    display: flex;
    flex-direction: column; }
    @media only screen and (max-width: 1019px) {
      .footer_type3 .footer__labels-block {
        align-items: center;
        margin-top: 28px; } }
    @media only screen and (min-width: 1020px) {
      .footer_type3 .footer__labels-block {
        align-items: flex-end;
        position: absolute;
        top: 66px;
        right: 0; } }
