/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ""; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir="rtl"] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-slider1 {
  background-color: #f7f8fa; }
  @media only screen and (max-width: 1019px) {
    .slick-slider1 {
      min-width: 375px; } }
  @media only screen and (max-width: 1019px) {
    .slick-slider1 .holder {
      padding-top: 40px;
      padding-bottom: 30px;
      width: auto; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider1 .holder {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider1 .slick-wrapper {
      padding: 0 40px; } }
  .slick-slider1 .slick-title {
    text-align: center; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1 .slick-title {
        margin-bottom: 40px;
        padding: 0 25px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .slick-title {
        margin-bottom: 40px; } }
  .slick-slider1 .slick-slide > div {
    position: relative; }
    .slick-slider1 .slick-slide > div:before {
      content: "";
      position: absolute;
      bottom: 20px;
      left: 50%;
      height: 125px;
      border-radius: 10px;
      box-shadow: 0 0 20px 0 rgba(109, 115, 147, 0.49); }
      @media only screen and (max-width: 1019px) {
        .slick-slider1 .slick-slide > div:before {
          width: 198px;
          margin-left: -99px; } }
      @media only screen and (min-width: 1020px) {
        .slick-slider1 .slick-slide > div:before {
          width: 160px;
          margin-left: -80px; } }
  .slick-slider1 .slick-item {
    position: relative;
    border: solid 1px #c8cfe7;
    border-radius: 15px;
    background-color: #fff; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1 .slick-item {
        width: 265px !important;
        height: 238px;
        padding: 22px 16px 0;
        margin: 0 0 20px 20px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .slick-item {
        width: 210px !important;
        height: 190px;
        padding: 14px 13px 0;
        margin: 0 8px 20px; } }
  .slick-slider1 .item-title {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    color: #4e526b; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1 .item-title {
        font-size: 20px;
        line-height: 1.2; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .item-title {
        font-size: 16px;
        line-height: 1.5; } }
    .slick-slider1 .item-title:after {
      content: "";
      display: block;
      width: 39px;
      height: 3px;
      border-radius: 1.5px;
      background-color: #24a652; }
      @media only screen and (max-width: 1019px) {
        .slick-slider1 .item-title:after {
          margin: 18px 0 16px; } }
      @media only screen and (min-width: 1020px) {
        .slick-slider1 .item-title:after {
          margin: 13px 0 16px; } }
  .slick-slider1 .item-msg {
    font-family: "Roboto", sans-serif;
    font-style: italic;
    line-height: 1.5;
    color: #6d7393;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1 .item-msg {
        font-size: 18px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .item-msg {
        font-size: 14px; } }
  .slick-slider1 .slick-arrow {
    text-indent: -99999em;
    white-space: nowrap;
    overflow: hidden; }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .slick-arrow {
        position: absolute;
        display: inline-block;
        width: 14px;
        height: 60px;
        top: 50%;
        margin-top: -42px;
        transition: opacity 0.3s ease;
        background-image: url("./img/slider-arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px 60px;
        cursor: pointer; }
        .slick-slider1 .slick-arrow.slick-disabled {
          opacity: 0.1; }
        .slick-slider1 .slick-arrow:hover {
          background-image: url("./img/slider-arrow-active.svg"); } }
  .slick-slider1 .slick-prev {
    left: -40px;
    transform: scale(-1, 1); }
  .slick-slider1 .slick-next {
    right: -40px; }
  .slick-slider1 .slick-honor-line {
    font-family: "Roboto", sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #797c91;
    margin-top: 33px; }
    .slick-slider1 .slick-honor-line .link {
      text-decoration: underline;
      white-space: nowrap; }
      .slick-slider1 .slick-honor-line .link:hover {
        text-decoration: none; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1 .slick-honor-line {
        font-size: 14px;
        padding: 0 35px;
        margin-top: 32px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1 .slick-honor-line {
        opacity: 0.8;
        font-size: 14px;
        margin-top: 20px; } }
  @media only screen and (max-width: 1019px) {
    .slick-slider1 .slick-dots {
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin-top: 20px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider1 .slick-dots {
      display: none !important; } }
  @media only screen and (max-width: 1019px) {
    .slick-slider1 li button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin: 0 9px;
      background-color: #dfdfdf;
      transition: background 0.3s ease;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden; } }
  .slick-slider1 li.slick-active button {
    background-color: #1c2036; }
  .slick-slider1.slick-slider1_2 {
    background-color: #e6ebfc; }
    .slick-slider1.slick-slider1_2 .item-title:after {
      background-color: #225ee8; }
  .slick-slider1.slick-slider1_3 {
    background-color: #feeda1; }
    .slick-slider1.slick-slider1_3 .item-title:after {
      background-color: #feeda1; }

.slick-slider1_clp3 {
  background-color: #fff; }
  @media only screen and (max-width: 1019px) {
    .slick-slider1_clp3 .holder {
      padding: 60px 20px;
      width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider1_clp3 .holder {
      padding-top: 80px; } }
  .slick-slider1_clp3 .slick-title {
    font-family: 'Axiforma',sans-serif;
    font-size: 26px;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: 0.36px;
    text-align: center;
    color: #023135; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .slick-title {
        padding: 0;
        margin-bottom: 28px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .slick-title {
        margin-bottom: 20px; } }
  .slick-slider1_clp3 .slick-slide > div:before {
    display: none; }
  @media only screen and (max-width: 1019px) {
    .slick-slider1_clp3 .slick-slide > div {
      width: 335px; } }
  .slick-slider1_clp3 .slick-item {
    border-radius: 8px;
    border: solid 1px #dadada; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .slick-item {
        width: 245px !important;
        padding: 20px 13px 20px;
        height: 205px;
        margin: 0 45px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .slick-item {
        width: 200px !important;
        padding: 24px 16px 16px;
        height: 205px;
        margin: 0 12px; } }
  .slick-slider1_clp3 .item-title {
    font-family: 'Axiforma',sans-serif;
    font-weight: bold;
    color: #131415; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .item-title {
        font-size: 20px;
        letter-spacing: 0.28px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .item-title {
        font-size: 16px;
        letter-spacing: 0.22px; } }
    .slick-slider1_clp3 .item-title:after {
      display: none; }
  .slick-slider1_clp3 .item-msg {
    font-family: 'Axiforma',sans-serif;
    color: #637381;
    font-style: normal; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .item-msg {
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.22px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .item-msg {
        margin-top: 8px;
        font-size: 13px;
        line-height: 1.46;
        letter-spacing: 0.18px; } }
  .slick-slider1_clp3 .slick-arrow {
    width: 15px;
    height: 40px;
    background-image: url("./img/slider-arrow-active.svg");
    background-size: cover; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .slick-arrow {
        position: absolute;
        display: inline-block;
        top: 50%;
        margin-top: -42px;
        transition: opacity 0.3s ease;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 100; }
        .slick-slider1_clp3 .slick-arrow.slick-disabled {
          opacity: 0.1; }
        .slick-slider1_clp3 .slick-arrow:hover {
          background-image: url("./img/slider-arrow-active.svg"); } }
  .slick-slider1_clp3 .slick-prev {
    transform: scale(-1, 1); }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .slick-prev {
        left: 0;
        margin-top: -20px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .slick-prev {
        left: -40px; } }
  @media only screen and (max-width: 1019px) {
    .slick-slider1_clp3 .slick-next {
      right: 0;
      margin-top: -20px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider1_clp3 .slick-next {
      right: -40px; } }
  .slick-slider1_clp3 .slick-honor-line {
    font-family: 'Axiforma',sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: #797c91; }
    @media only screen and (max-width: 1019px) {
      .slick-slider1_clp3 .slick-honor-line {
        margin-top: 18px;
        padding: 0; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider1_clp3 .slick-honor-line {
        margin-top: 40px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
