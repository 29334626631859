.verifiedPurchase {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .verifiedPurchase .imgIcon {
    width: 11px;
    height: 11px;
    margin-right: 5px; }
  .verifiedPurchase .verifiedPurchaseText {
    font-family: Roboto;
    font-size: 11px;
    font-weight: bold;
    color: #abacb8; }
