.pay-list-block {
  /*
  .applepay-img {
    @include respond-to(handhelds) {
    }
    @include respond-to(wide-screens) {
      width: 70px;
      height: 45px;
    }
  }
  .google-pay-img {
    @include respond-to(handhelds) {
    }
    @include respond-to(wide-screens) {
      width: 70px;
      height: 45px;
    }
  }
  .visa-img {
    width: 39px;
    height: 25px;
  }
  .mastercard-img {
    width: 39px;
    height: 25px;
  }
  .ae-img {
    width: 39px;
    height: 25px;
  }
  .discover-img {
    width: 39px;
    height: 25px;
  }
  .pp-img {
    width: 39px;
    height: 25px;
  }
  */ }
  .pay-list-block .holder {
    padding: 28px 0 0; }
  .pay-list-block .pay-list {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 1019px) {
      .pay-list-block .pay-list.top .pay-list-img {
        width: 65px;
        height: 42px; } }
    @media only screen and (min-width: 1020px) {
      .pay-list-block .pay-list.top .pay-list-img {
        width: 70px;
        height: 45px; } }
    @media only screen and (max-width: 1019px) {
      .pay-list-block .pay-list.bottom {
        margin-top: 23px; } }
    @media only screen and (min-width: 1020px) {
      .pay-list-block .pay-list.bottom {
        margin-top: 20px; } }
    .pay-list-block .pay-list.bottom .pay-list-img {
      width: 39px;
      height: 25px; }
  @media only screen and (max-width: 1019px) {
    .pay-list-block .pay-list-item {
      margin: 0 11px; } }
  @media only screen and (min-width: 1020px) {
    .pay-list-block .pay-list-item {
      margin: 0 12px; } }
  .pay-list-block .pay-list-img {
    display: block; }

@media only screen and (max-width: 1019px) {
  .paylist_clp3 .holder {
    padding: 27px 40px 0; } }

@media only screen and (min-width: 1020px) {
  .paylist_clp3 .holder {
    padding-top: 37px; } }

.paylist_clp3 .pay-list {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1019px) {
    .paylist_clp3 .pay-list {
      flex-wrap: wrap; } }

.paylist_clp3 .pay-list-item {
  margin: 0 10px; }
  @media only screen and (max-width: 1019px) {
    .paylist_clp3 .pay-list-item {
      margin-top: 12px; } }

.paylist_clp3 .pay-list-img {
  display: block;
  width: 47px;
  height: 30px; }

/*
    @include respond-to(handhelds) {
    }
    @include respond-to(wide-screens) {
    }
*/
