@media only screen and (max-width: 1019px) {
  .save-time-block .holder {
    padding: 80px 15px; } }

@media only screen and (min-width: 1020px) {
  .save-time-block .holder {
    padding-top: 70px;
    padding-bottom: 70px; } }

@media only screen and (min-width: 1020px) {
  .save-time-block .save-time-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 48px 40px 0 97px; } }

.save-time-block .save-time-title {
  text-align: center; }

@media only screen and (max-width: 1019px) {
  .save-time-block .save-time-list {
    margin: 32px 24px; } }

@media only screen and (min-width: 1020px) {
  .save-time-block .save-time-list {
    width: 390px; } }

.save-time-block .save-time-list .list-title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .save-time-block .save-time-list .list-title {
      text-align: center;
      font-size: 18px;
      margin-bottom: 12px; } }
  @media only screen and (min-width: 1020px) {
    .save-time-block .save-time-list .list-title {
      font-size: 16px;
      margin-bottom: 23px; } }

.save-time-block .save-time-list .list-item {
  display: flex; }
  @media only screen and (max-width: 1019px) {
    .save-time-block .save-time-list .list-item:not(:first-child) {
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .save-time-block .save-time-list .list-item:not(:first-child) {
      margin-top: 23px; } }

.save-time-block .save-time-list .list-item-img {
  display: block;
  width: 20px;
  height: 20px;
  margin-top: 3px; }

.save-time-block .save-time-list .list-item-msg {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #4e526b;
  margin-left: 20px;
  flex: 1; }

@media only screen and (max-width: 1019px) {
  .save-time-block.save-time-block__clp3 .holder {
    padding: 80px 20px 60px; } }

@media only screen and (min-width: 1020px) {
  .save-time-block.save-time-block__clp3 .holder {
    padding-top: 110px;
    padding-bottom: 80px; } }

.save-time-block.save-time-block__clp3 .save-time-title {
  font-family: 'Axiforma',sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.36px;
  color: #023135; }

.save-time-block.save-time-block__clp3 .save-time-wrapper {
  margin-top: 40px; }

.save-time-block.save-time-block__clp3 .list-title {
  font-family: 'Axiforma',sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  color: #131415; }
  @media only screen and (max-width: 1019px) {
    .save-time-block.save-time-block__clp3 .list-title {
      text-align: left; } }

.save-time-block.save-time-block__clp3 .list-item-msg {
  font-family: 'Axiforma',sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #4e526b;
  margin-left: 16px; }

.save-time-block.save-time-block__clp3 .list-item-img {
  margin-top: 0; }

@media only screen and (max-width: 1019px) {
  .save-time-block.save-time-block__clp3 .save-time-list {
    margin: 40px 0 0; } }
