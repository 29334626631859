@media only screen and (max-width: 1019px) {
  .slick-slider_clp3 {
    min-width: 375px; } }

.slick-slider_clp3 .slick-title {
  text-align: center;
  padding: 0 20px;
  margin-bottom: 44px;
  font-family: 'Axiforma',sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.36px;
  color: #023135;
  position: relative; }
  .slick-slider_clp3 .slick-title:before, .slick-slider_clp3 .slick-title:after {
    content: '';
    position: absolute;
    pointer-events: none; }
  .slick-slider_clp3 .slick-title:before {
    width: 9px;
    height: 9px;
    background-image: url("img/el1@2x.png");
    background-size: cover; }
    @media only screen and (max-width: 1019px) {
      .slick-slider_clp3 .slick-title:before {
        top: 48px;
        left: 20px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider_clp3 .slick-title:before {
        top: 116px;
        left: 169px; } }
  .slick-slider_clp3 .slick-title:after {
    width: 14px;
    height: 14px;
    background-image: url("img/el2@2x.png");
    background-size: cover; }
    @media only screen and (max-width: 1019px) {
      .slick-slider_clp3 .slick-title:after {
        top: 133px;
        right: 20px; } }
    @media only screen and (min-width: 1020px) {
      .slick-slider_clp3 .slick-title:after {
        top: 153px;
        right: 173px; } }
  @media only screen and (max-width: 1019px) {
    .slick-slider_clp3 .slick-title {
      padding: 60px 20px 0; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider_clp3 .slick-title {
      padding: 120px 20px 0; } }

.slick-slider_clp3 .slick-track {
  width: 900px !important; }

@media only screen and (max-width: 1019px) {
  .slick-slider_clp3 .slick-slide {
    width: 292px; } }

@media only screen and (min-width: 1020px) {
  .slick-slider_clp3 .slick-slide {
    /*
      &:not(:first-child) {
        margin-left: 56px;
      }
      */ } }

@media only screen and (max-width: 1019px) {
  .slick-slider_clp3 .slick-slide > div {
    margin: 0 8px; } }

.slick-slider_clp3 .slick-slide:nth-child(1) .slick-item {
  background-color: #00848e;
  background-image: url("img/book.png");
  background-position: bottom center; }

.slick-slider_clp3 .slick-slide:nth-child(2) .slick-item {
  background-color: #084e8a;
  background-image: url("img/workflow.png");
  background-position: bottom center; }

.slick-slider_clp3 .slick-slide:nth-child(3) .slick-item {
  background-color: #4a4c93;
  background-image: url("img/list-is-empty.png");
  background-position: bottom right; }

.slick-slider_clp3 .slick-static .slick-item:nth-child(1) {
  background-color: #00848e;
  background-image: url("img/book.png");
  background-position: bottom center; }

.slick-slider_clp3 .slick-static .slick-item:nth-child(2) {
  background-color: #084e8a;
  background-image: url("img/workflow.png");
  background-position: bottom center; }

.slick-slider_clp3 .slick-static .slick-item:nth-child(3) {
  background-color: #4a4c93;
  background-image: url("img/list-is-empty.png");
  background-position: bottom right; }

.slick-slider_clp3 .slick-item {
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 300px;
  border-radius: 15px;
  background-repeat: no-repeat;
  padding-top: 28px;
  font-family: 'Axiforma',sans-serif;
  text-align: center;
  color: #fff; }
  @media only screen and (min-width: 1020px) {
    .slick-slider_clp3 .slick-item {
      width: 290px !important; } }

.slick-slider_clp3 .item-tag {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35; }

.slick-slider_clp3 .item-title {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  margin-top: 6px; }

.slick-slider_clp3 .slick-arrow {
  display: none !important; }

@media only screen and (max-width: 1019px) {
  .slick-slider_clp3 .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 16px; } }

@media only screen and (min-width: 1020px) {
  .slick-slider_clp3 .slick-dots {
    display: none !important; } }

@media only screen and (max-width: 1019px) {
  .slick-slider_clp3 li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 6px;
    background-color: #d6d6d6;
    transition: background .3s ease;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; } }

.slick-slider_clp3 li.slick-active button {
  background-color: #016fbb; }

@media only screen and (min-width: 1020px) {
  .slick-slider_clp3 .slick-static {
    display: flex;
    justify-content: space-between;
    align-items: top; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
