.input-select__scroll .scrollbar-wrap__track {
  display: block;
  background: #f7f8fa;
  width: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-left: solid 1px #e4e5eb; }

.input-select__scroll .scrollbar-wrap__thumb {
  background: #797c91;
  width: 7px;
  margin-left: 4px;
  border-radius: 4px; }

.scrollbar-wrap__track {
  display: none; }
