html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
 * ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
 * ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit; }
  a:active, a:hover {
    outline: 0; }

/* Text-level semantics
 * ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
 * ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
 * ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
  border: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
 * ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }
  button:focus,
  input:focus,
  optgroup:focus,
  select:focus,
  textarea:focus {
    outline: none; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
  cursor: pointer;
  padding: 0;
  background-color: transparent; }
  button::-moz-focus-inner {
    border: 0; }

button, input, textarea {
  border: none; }

input {
  box-sizing: border-box; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  padding: 0; }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
 * ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

i, address {
  font-style: normal; }

ol, ul {
  list-style: none; }

/* aleo-regular - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./../fonts/aleo-v3-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Regular"), local("Aleo-Regular"), url("./../fonts/aleo-v3-latin-regular.eot?#iefix") format("embedded-opentype"), url("./../fonts/aleo-v3-latin-regular.woff2") format("woff2"), url("./../fonts/aleo-v3-latin-regular.woff") format("woff"), url("./../fonts/aleo-v3-latin-regular.ttf") format("truetype"), url("./../fonts/aleo-v3-latin-regular.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* aleo-700 - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./../fonts/aleo-v3-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Bold"), local("Aleo-Bold"), url("./../fonts/aleo-v3-latin-700.eot?#iefix") format("embedded-opentype"), url("./../fonts/aleo-v3-latin-700.woff2") format("woff2"), url("./../fonts/aleo-v3-latin-700.woff") format("woff"), url("./../fonts/aleo-v3-latin-700.ttf") format("truetype"), url("./../fonts/aleo-v3-latin-700.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* aleo-italic - latin */
@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/aleo-v3-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Italic"), local("Aleo-Italic"), url("../fonts/aleo-v3-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo-v3-latin-italic.woff2") format("woff2"), url("../fonts/aleo-v3-latin-italic.woff") format("woff"), url("../fonts/aleo-v3-latin-italic.ttf") format("truetype"), url("../fonts/aleo-v3-latin-italic.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-300.woff2") format("woff2"), url("../fonts/roboto-v20-latin-300.woff") format("woff"), url("../fonts/roboto-v20-latin-300.ttf") format("truetype"), url("../fonts/roboto-v20-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../fonts/roboto-v20-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-300italic.woff2") format("woff2"), url("../fonts/roboto-v20-latin-300italic.woff") format("woff"), url("../fonts/roboto-v20-latin-300italic.ttf") format("truetype"), url("../fonts/roboto-v20-latin-300italic.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v20-latin-regular.woff") format("woff"), url("../fonts/roboto-v20-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-500.woff2") format("woff2"), url("../fonts/roboto-v20-latin-500.woff") format("woff"), url("../fonts/roboto-v20-latin-500.ttf") format("truetype"), url("../fonts/roboto-v20-latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-700.woff2") format("woff2"), url("../fonts/roboto-v20-latin-700.woff") format("woff"), url("../fonts/roboto-v20-latin-700.ttf") format("truetype"), url("../fonts/roboto-v20-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* Axiforma - latin */
@font-face {
  font-family: 'Axiforma';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("../fonts/Axiforma-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Regular.woff2") format("woff2"), url("../fonts/Axiforma-Regular.woff") format("woff"), url("../fonts/Axiforma-Regular.ttf") format("truetype"), url("../fonts/Axiforma-Regular.svg#Axiforma") format("svg"); }

@font-face {
  font-family: 'Axiforma';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Medium.eot");
  src: local("Axiforma Medium"), local("Axiforma-Medium"), url("../fonts/Axiforma-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Medium.woff") format("woff"), url("../fonts/Axiforma-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Axiforma';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("../fonts/Axiforma-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Bold.woff") format("woff"), url("../fonts/Axiforma-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-regular.woff2") format("woff2"), url("../fonts/manrope-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-semibold.woff2") format("woff2"), url("../fonts/manrope-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-bold.woff2") format("woff2"), url("../fonts/manrope-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@keyframes move {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 50px 50px; } }

.pt20 {
  padding-top: 20px; }

@media only screen and (max-width: 1019px) {
  .pt20m {
    padding-top: 20px; } }

@media only screen and (min-width: 1020px) {
  .pt20d {
    padding-top: 20px; } }

.pb20 {
  padding-bottom: 20px; }

@media only screen and (max-width: 1019px) {
  .pb20m {
    padding-bottom: 20px; } }

@media only screen and (min-width: 1020px) {
  .pb20d {
    padding-bottom: 20px; } }

.pt30 {
  padding-top: 30px; }

@media only screen and (max-width: 1019px) {
  .pt30m {
    padding-top: 30px; } }

@media only screen and (min-width: 1020px) {
  .pt30d {
    padding-top: 30px; } }

.pb30 {
  padding-bottom: 30px; }

@media only screen and (max-width: 1019px) {
  .pb30m {
    padding-bottom: 30px; } }

@media only screen and (min-width: 1020px) {
  .pb30d {
    padding-bottom: 30px; } }

.pt40 {
  padding-top: 40px; }

@media only screen and (max-width: 1019px) {
  .pt40m {
    padding-top: 40px; } }

@media only screen and (min-width: 1020px) {
  .pt40d {
    padding-top: 40px; } }

.pb40 {
  padding-bottom: 40px; }

@media only screen and (max-width: 1019px) {
  .pb40m {
    padding-bottom: 40px; } }

@media only screen and (min-width: 1020px) {
  .pb40d {
    padding-bottom: 40px; } }

.pt50 {
  padding-top: 50px; }

@media only screen and (max-width: 1019px) {
  .pt50m {
    padding-top: 50px; } }

@media only screen and (min-width: 1020px) {
  .pt50d {
    padding-top: 50px; } }

.pb50 {
  padding-bottom: 50px; }

@media only screen and (max-width: 1019px) {
  .pb50m {
    padding-bottom: 50px; } }

@media only screen and (min-width: 1020px) {
  .pb50d {
    padding-bottom: 50px; } }

.pt60 {
  padding-top: 60px; }

@media only screen and (max-width: 1019px) {
  .pt60m {
    padding-top: 60px; } }

@media only screen and (min-width: 1020px) {
  .pt60d {
    padding-top: 60px; } }

.pb60 {
  padding-bottom: 60px; }

@media only screen and (max-width: 1019px) {
  .pb60m {
    padding-bottom: 60px; } }

@media only screen and (min-width: 1020px) {
  .pb60d {
    padding-bottom: 60px; } }

.pt70 {
  padding-top: 70px; }

@media only screen and (max-width: 1019px) {
  .pt70m {
    padding-top: 70px; } }

@media only screen and (min-width: 1020px) {
  .pt70d {
    padding-top: 70px; } }

.pb70 {
  padding-bottom: 70px; }

@media only screen and (max-width: 1019px) {
  .pb70m {
    padding-bottom: 70px; } }

@media only screen and (min-width: 1020px) {
  .pb70d {
    padding-bottom: 70px; } }

.pt80 {
  padding-top: 80px; }

@media only screen and (max-width: 1019px) {
  .pt80m {
    padding-top: 80px; } }

@media only screen and (min-width: 1020px) {
  .pt80d {
    padding-top: 80px; } }

.pb80 {
  padding-bottom: 80px; }

@media only screen and (max-width: 1019px) {
  .pb80m {
    padding-bottom: 80px; } }

@media only screen and (min-width: 1020px) {
  .pb80d {
    padding-bottom: 80px; } }

.mt20 {
  margin-top: 20px; }

@media only screen and (max-width: 1019px) {
  .mt20m {
    margin-top: 20px; } }

@media only screen and (min-width: 1020px) {
  .mt20d {
    margin-top: 20px; } }

.mb20 {
  margin-bottom: 20px; }

@media only screen and (max-width: 1019px) {
  .mb20m {
    margin-bottom: 20px; } }

@media only screen and (min-width: 1020px) {
  .mb20d {
    margin-bottom: 20px; } }

.mt30 {
  margin-top: 30px; }

@media only screen and (max-width: 1019px) {
  .mt30m {
    margin-top: 30px; } }

@media only screen and (min-width: 1020px) {
  .mt30d {
    margin-top: 30px; } }

.mb30 {
  margin-bottom: 30px; }

@media only screen and (max-width: 1019px) {
  .mb30m {
    margin-bottom: 30px; } }

@media only screen and (min-width: 1020px) {
  .mb30d {
    margin-bottom: 30px; } }

.mt40 {
  margin-top: 40px; }

@media only screen and (max-width: 1019px) {
  .mt40m {
    margin-top: 40px; } }

@media only screen and (min-width: 1020px) {
  .mt40d {
    margin-top: 40px; } }

.mb40 {
  margin-bottom: 40px; }

@media only screen and (max-width: 1019px) {
  .mb40m {
    margin-bottom: 40px; } }

@media only screen and (min-width: 1020px) {
  .mb40d {
    margin-bottom: 40px; } }

.mt60 {
  margin-top: 60px; }

@media only screen and (max-width: 1019px) {
  .mt60m {
    margin-top: 60px; } }

@media only screen and (min-width: 1020px) {
  .mt60d {
    margin-top: 60px; } }

.mb60 {
  margin-bottom: 60px; }

@media only screen and (max-width: 1019px) {
  .mb60m {
    margin-bottom: 60px; } }

@media only screen and (min-width: 1020px) {
  .mb60d {
    margin-bottom: 60px; } }

.mt70 {
  margin-top: 70px; }

@media only screen and (max-width: 1019px) {
  .mt70m {
    margin-top: 70px; } }

@media only screen and (min-width: 1020px) {
  .mt70d {
    margin-top: 70px; } }

.mb70 {
  margin-bottom: 70px; }

@media only screen and (max-width: 1019px) {
  .mb70m {
    margin-bottom: 70px; } }

@media only screen and (min-width: 1020px) {
  .mb70d {
    margin-bottom: 70px; } }

.mt80 {
  margin-top: 80px; }

@media only screen and (max-width: 1019px) {
  .mt80m {
    margin-top: 80px; } }

@media only screen and (min-width: 1020px) {
  .mt80d {
    margin-top: 80px; } }

.mb80 {
  margin-bottom: 80px; }

@media only screen and (max-width: 1019px) {
  .mb80m {
    margin-bottom: 80px; } }

@media only screen and (min-width: 1020px) {
  .mb80d {
    margin-bottom: 80px; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
.button {
  font-family: "Roboto", sans-serif;
  transition: background 300ms ease, border 300ms ease, color 300ms ease;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.button-icon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: block;
  fill: #fff;
  transition: fill 300ms ease; }

.button__type1 {
  background: #0084ff;
  min-width: 300px;
  height: 60px;
  padding: 0 20px;
  color: #fff;
  font-size: 18px;
  text-transform: none;
  border-radius: 100px; }
  .button__type1:hover {
    background: #3aa0ff; }
  .button__type1 .button-icon {
    fill: #fff; }

.button__type2 {
  background: #0084ff;
  min-width: 240px;
  height: 50px;
  padding: 0 20px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  border-radius: 100px; }
  .button__type2:hover {
    background: #3aa0ff; }
  .button__type2 .button-icon {
    fill: #fff; }

.button__type2a {
  background: #0084ff;
  color: #fff;
  text-transform: none;
  border-radius: 100px; }
  .button__type2a:hover {
    background: #3aa0ff; }
  .button__type2a .button-icon {
    fill: #fff; }
  @media only screen and (max-width: 1019px) {
    .button__type2a {
      font-size: 14px;
      font-weight: bold;
      min-width: 126px;
      height: 37px;
      padding: 0 12px; }
      .button__type2a .button-icon {
        width: 17px;
        height: 17px;
        margin-right: 8px; } }
  @media only screen and (min-width: 1020px) {
    .button__type2a {
      font-size: 16px;
      font-weight: normal;
      min-width: 240px;
      height: 50px;
      padding: 0 20px; } }

.button__type3 {
  background: transparent;
  min-width: 300px;
  height: 60px;
  padding: 0 20px;
  font-size: 18px;
  color: #0084ff;
  border-radius: 100px;
  border: 1px solid #0084ff; }
  .button__type3:hover {
    background: transparent;
    border-color: #3aa0ff;
    color: #3aa0ff; }
    .button__type3:hover .button-icon {
      fill: #3aa0ff; }
  .button__type3 .button-icon {
    fill: #0084ff; }

.button__type4 {
  background: transparent;
  min-width: 240px;
  height: 50px;
  padding: 0 20px;
  color: #0084ff;
  font-size: 16px;
  font-weight: normal;
  text-transform: none;
  border-radius: 100px;
  border: 1px solid #0084ff; }
  .button__type4:hover {
    background: transparent;
    border-color: #3aa0ff;
    color: #3aa0ff; }
    .button__type4:hover .button-icon {
      fill: #3aa0ff; }
  .button__type4 .button-icon {
    fill: #0084ff; }

.button__type5 {
  background: #24a652;
  min-width: 220px;
  height: 60px;
  padding: 0 20px;
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 0.5px; }
  .button__type5:hover {
    background: #27b659; }

.button__type6 {
  background: #24a652;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 220px;
  height: 45px;
  padding: 0 15px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 0.5px; }
  .button__type6:hover {
    background: #27b659; }
  .button__type6[disabled] {
    background: #a4e4ba; }
  @media only screen and (max-width: 1019px) {
    .button__type6.button__type6_mob_full_length {
      width: 100%; } }
  .button__type6.submit-btn {
    display: flex;
    justify-content: center;
    align-items: center; }
    .button__type6.submit-btn.submitted {
      background-color: #abacb8; }
    .button__type6.submit-btn .loading {
      position: relative;
      width: 26px;
      height: 26px; }
      .button__type6.submit-btn .loading li {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 26px;
        height: 26px;
        border: 3px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent; }
      .button__type6.submit-btn .loading li:nth-child(1) {
        animation-delay: -0.45s; }
      .button__type6.submit-btn .loading li:nth-child(2) {
        animation-delay: -0.3s; }
      .button__type6.submit-btn .loading li:nth-child(3) {
        animation-delay: -0.15s; }

.button__type7 {
  background: transparent;
  min-width: 220px;
  height: 60px;
  padding: 0 20px;
  color: #24a652;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
  letter-spacing: 0.5px;
  border: 1px solid #24a652; }
  .button__type7:hover {
    background: #24a652;
    color: #fff; }

.button__type8 {
  background: transparent;
  min-width: 220px;
  height: 45px;
  padding: 0 40px;
  color: #24a652;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  letter-spacing: 0.5px;
  border: 1px solid #24a652; }
  .button__type8:hover {
    background: #24a652;
    color: #fff; }

.button__type9 {
  background: #fff;
  width: auto;
  height: 34px;
  padding: 0 20px;
  color: #24a652;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border-radius: 18px;
  border: 1px solid #24a652; }
  .button__type9:hover {
    background: #24a652;
    color: #fff; }

.button__type10 {
  background: #24a652;
  width: auto;
  height: 34px;
  padding: 0 20px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  border-radius: 18px; }
  .button__type10:hover {
    background: #27b659; }

.button__type11 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: auto;
  padding: 0 36px;
  color: #fa1d47;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.3px;
  height: 46px;
  border-radius: 23px;
  border: 1px solid #fa1d47; }

.button__type12 {
  font-family: "Roboto", sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #24a652;
  height: 34px;
  padding: 0 45px;
  color: #fff;
  font-size: 14px;
  border-radius: 18px; }
  .button__type12:hover {
    background: #27b659; }
  .button__type12 .svg-icon {
    width: 19px;
    height: 16px;
    margin-right: 8px; }

.button__type13 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  transition: background 300ms ease, border 300ms ease, color 300ms ease;
  background: #24a652;
  min-width: 240px;
  height: 50px;
  padding: 0 20px;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
  border-radius: 25px; }
  .button__type13:hover {
    background: #27b659; }

.button__type14 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  border-radius: 22.5px;
  border: solid 1px #24a652;
  background-color: #fff;
  color: #24a652;
  transition: background 300ms ease; }
  @media only screen and (min-width: 1020px) {
    .button__type14 {
      position: relative; } }
  .button__type14 .small-arrow {
    display: block;
    width: 6px;
    height: 10px;
    fill: #24a652;
    transition: left 300ms ease; }
    @media only screen and (max-width: 1019px) {
      .button__type14 .small-arrow {
        margin-right: 12px; } }
    @media only screen and (min-width: 1020px) {
      .button__type14 .small-arrow {
        position: absolute;
        left: 30px;
        top: 12px; } }
  .button__type14:hover {
    background: #27b659;
    color: #fff; }
    .button__type14:hover .small-arrow {
      fill: #fff; }
      @media only screen and (min-width: 1020px) {
        .button__type14:hover .small-arrow {
          left: 20px; } }
  @media only screen and (max-width: 1019px) {
    .button__type14 {
      font-size: 16px;
      height: 45px;
      width: 227px; } }
  @media only screen and (min-width: 1020px) {
    .button__type14 {
      font-size: 14px;
      height: 35px;
      padding: 0 30px 0 46px; } }

.button__type15 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #24a652;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  letter-spacing: 0.5px;
  color: #fff;
  width: 300px;
  height: 60px;
  cursor: pointer; }

.button__type16 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 45px;
  border-radius: 8px;
  border: solid 1px #24a652;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #24a652;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase; }

.button__type17 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-radius: 10px;
  background-color: #24a652;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase; }
  @media only screen and (max-width: 1019px) {
    .button__type17 {
      width: 100%; } }
  @media only screen and (min-width: 1020px) {
    .button__type17 {
      width: 320px; } }
  .button__type17:disabled {
    cursor: default;
    opacity: 0.6; }

.button__type18 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  border-radius: 26.5px;
  background-color: #24a652;
  font-family: "Aleo", serif;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding: 0 51px; }

.button__type19 {
  height: 46px;
  border-radius: 26.5px;
  border: solid 2px #24a652;
  background-color: #fff;
  font-family: "Aleo", serif;
  font-size: 16px;
  font-weight: bold;
  color: #24a652;
  padding: 0 23px; }

.premium {
  padding: 3px 10px;
  border-radius: 6px;
  background-color: #27b659;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff;
  display: inline-block; }

.button__top {
  position: absolute;
  right: 0;
  top: 0; }
  @media only screen and (max-width: 1019px) {
    .button__top {
      margin-bottom: 28px; } }

@media only screen and (max-width: 1019px) {
  .button__bottom,
  .button__top {
    position: relative;
    display: flex;
    max-width: 335px;
    width: 100%; } }

.hr {
  background-color: rgba(228, 229, 235, 0.8);
  height: 2px;
  margin: 0 auto; }
  @media only screen and (max-width: 1019px) {
    .hr {
      width: 290px; } }
  @media only screen and (min-width: 1020px) {
    .hr {
      width: 600px; } }

@media only screen and (max-width: 1019px) {
  .hr_1 {
    width: auto; } }

@media only screen and (min-width: 1020px) {
  .hr_1 {
    width: auto; } }

.hr_2 {
  width: 100%; }
  @media only screen and (max-width: 1019px) {
    .hr_2 {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    .hr_2 {
      min-width: 985px; } }

@media only screen and (min-width: 1020px) {
  .hr_3 {
    width: 290px; } }

@media only screen and (max-width: 1019px) {
  .hr_4 {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .hr_4 {
    border: 0;
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background-color: rgba(187, 187, 187, 0.5); } }

.hr_5 {
  border: 0;
  margin: 0 auto;
  width: 100%;
  height: 1px;
  background-color: rgba(187, 187, 187, 0.5); }

.hr_6 {
  display: block;
  width: 137px;
  height: 1px;
  background-color: #dcdcdc;
  margin: 0 auto; }

.hr_or {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Aleo", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #fff; }
  .hr_or:before, .hr_or:after {
    content: '';
    width: 105px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.4); }
  .hr_or:before {
    margin-right: 18px; }
  .hr_or:after {
    margin-left: 18px; }

.hr_or_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Aleo", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #31354f; }
  .hr_or_2:before, .hr_or_2:after {
    content: '';
    width: 105px;
    height: 1px;
    background-color: rgba(49, 53, 79, 0.4); }
  .hr_or_2:before {
    margin-right: 18px; }
  .hr_or_2:after {
    margin-left: 18px; }

/* aleo-regular - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./../fonts/aleo-v3-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Regular"), local("Aleo-Regular"), url("./../fonts/aleo-v3-latin-regular.eot?#iefix") format("embedded-opentype"), url("./../fonts/aleo-v3-latin-regular.woff2") format("woff2"), url("./../fonts/aleo-v3-latin-regular.woff") format("woff"), url("./../fonts/aleo-v3-latin-regular.ttf") format("truetype"), url("./../fonts/aleo-v3-latin-regular.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* aleo-700 - latin */
@font-face {
  font-family: 'Aleo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./../fonts/aleo-v3-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Bold"), local("Aleo-Bold"), url("./../fonts/aleo-v3-latin-700.eot?#iefix") format("embedded-opentype"), url("./../fonts/aleo-v3-latin-700.woff2") format("woff2"), url("./../fonts/aleo-v3-latin-700.woff") format("woff"), url("./../fonts/aleo-v3-latin-700.ttf") format("truetype"), url("./../fonts/aleo-v3-latin-700.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* aleo-italic - latin */
@font-face {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/aleo-v3-latin-italic.eot");
  /* IE9 Compat Modes */
  src: local("Aleo Italic"), local("Aleo-Italic"), url("../fonts/aleo-v3-latin-italic.eot?#iefix") format("embedded-opentype"), url("../fonts/aleo-v3-latin-italic.woff2") format("woff2"), url("../fonts/aleo-v3-latin-italic.woff") format("woff"), url("../fonts/aleo-v3-latin-italic.ttf") format("truetype"), url("../fonts/aleo-v3-latin-italic.svg#Aleo") format("svg");
  /* Legacy iOS */ }

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/roboto-v20-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-300.woff2") format("woff2"), url("../fonts/roboto-v20-latin-300.woff") format("woff"), url("../fonts/roboto-v20-latin-300.ttf") format("truetype"), url("../fonts/roboto-v20-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-300italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-300italic.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("../fonts/roboto-v20-latin-300italic.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-300italic.woff2") format("woff2"), url("../fonts/roboto-v20-latin-300italic.woff") format("woff"), url("../fonts/roboto-v20-latin-300italic.ttf") format("truetype"), url("../fonts/roboto-v20-latin-300italic.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v20-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v20-latin-regular.woff") format("woff"), url("../fonts/roboto-v20-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v20-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v20-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-500.woff2") format("woff2"), url("../fonts/roboto-v20-latin-500.woff") format("woff"), url("../fonts/roboto-v20-latin-500.ttf") format("truetype"), url("../fonts/roboto-v20-latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/roboto-v20-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v20-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-latin-700.woff2") format("woff2"), url("../fonts/roboto-v20-latin-700.woff") format("woff"), url("../fonts/roboto-v20-latin-700.ttf") format("truetype"), url("../fonts/roboto-v20-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* Axiforma - latin */
@font-face {
  font-family: 'Axiforma';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Regular.eot");
  src: local("Axiforma Regular"), local("Axiforma-Regular"), url("../fonts/Axiforma-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Regular.woff2") format("woff2"), url("../fonts/Axiforma-Regular.woff") format("woff"), url("../fonts/Axiforma-Regular.ttf") format("truetype"), url("../fonts/Axiforma-Regular.svg#Axiforma") format("svg"); }

@font-face {
  font-family: 'Axiforma';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Medium.eot");
  src: local("Axiforma Medium"), local("Axiforma-Medium"), url("../fonts/Axiforma-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Medium.woff") format("woff"), url("../fonts/Axiforma-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Axiforma';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/Axiforma-Bold.eot");
  src: local("Axiforma Bold"), local("Axiforma-Bold"), url("../fonts/Axiforma-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Axiforma-Bold.woff") format("woff"), url("../fonts/Axiforma-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-regular.woff2") format("woff2"), url("../fonts/manrope-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-semibold.woff2") format("woff2"), url("../fonts/manrope-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Manrope';
  src: url("../fonts/manrope-bold.woff2") format("woff2"), url("../fonts/manrope-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.cookie-popup {
  position: fixed;
  background: #FFF;
  box-shadow: 4px 4px 8px 0 rgba(37, 38, 93, 0.08), -4px -4px 8px 0px rgba(37, 38, 93, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  z-index: 999; }
  @media only screen and (max-width: 1019px) {
    .cookie-popup {
      left: 0;
      right: 0;
      bottom: 0;
      padding: 16px; } }
  @media only screen and (min-width: 1020px) {
    .cookie-popup {
      left: 20px;
      bottom: 20px;
      width: 512px;
      height: 188px;
      border-radius: 8px;
      padding: 24px; } }
  .cookie-popup .svg-icon {
    display: block; }
  .cookie-popup .message {
    font-family: "Roboto", sans-serif;
    color: #4E526B;
    font-size: 12px;
    line-height: 20px; }
  .cookie-popup .link {
    font-weight: 700;
    text-decoration-line: underline;
    white-space: nowrap; }
  .cookie-popup .buttons {
    display: flex;
    align-self: stretch;
    gap: 12px; }
    @media only screen and (max-width: 1019px) {
      .cookie-popup .buttons {
        flex-direction: column-reverse;
        margin-top: 16px; } }
    @media only screen and (min-width: 1020px) {
      .cookie-popup .buttons {
        margin-top: auto; } }
  .cookie-popup .button {
    font-family: 'Aleo', serif;
    font-size: 18px;
    display: flex;
    height: 44px;
    padding: 0 10px; }
    .cookie-popup .button.type1 {
      color: #24A652; }
      @media only screen and (max-width: 1019px) {
        .cookie-popup .button.type1 {
          flex-basis: 0; } }
    .cookie-popup .button.type2 {
      border-radius: 4px;
      border: 1px solid #24A652;
      background: #FFF;
      color: #24A652;
      flex-grow: 1; }
      @media only screen and (min-width: 1020px) {
        .cookie-popup .button.type2 {
          flex-basis: 0; } }
    .cookie-popup .button.type3 {
      border-radius: 4px;
      background: #24A652;
      color: #FFF;
      flex-grow: 1; }
      @media only screen and (min-width: 1020px) {
        .cookie-popup .button.type3 {
          flex-basis: 0; } }
  .cookie-popup .titleBlock {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center; }
  .cookie-popup .title {
    color: #4E526B;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; }
  @media only screen and (max-width: 1019px) {
    .cookie-popup .checkboxBlock {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .cookie-popup .checkboxBlock {
      display: flex;
      justify-content: space-between;
      margin-top: 20px; } }
  .cookie-popup .checkboxItem {
    display: flex;
    align-items: center; }
  .cookie-popup .hiddenCheckbox {
    display: none; }
  .cookie-popup .checkboxLabel {
    display: flex;
    gap: 8px;
    cursor: pointer; }
  .cookie-popup .checkboxTitle {
    color: #4E526B;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 1px; }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
::-ms-clear {
  display: none; }

* {
  word-break: break-word;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none; }
  *:focus {
    outline: 0 !important;
    outline-color: transparent !important;
    outline-width: 0 !important;
    outline-style: none !important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important; }

html, body {
  overflow-x: hidden; }
  @media only screen and (max-width: 1019px) {
    html, body {
      min-width: 375px; } }
  @media only screen and (min-width: 1020px) {
    html, body {
      min-width: 985px; } }

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff; }

@media only screen and (min-width: 1020px) {
  .mobile-only {
    display: none !important; } }

@media only screen and (max-width: 1019px) {
  .desktop-only {
    display: none !important; } }

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }
  .page > div {
    flex: 1; }

.hero-title {
  color: #1c2036;
  font-family: "Aleo", serif;
  line-height: 1.17;
  letter-spacing: 0.8px; }
  @media only screen and (max-width: 1019px) {
    .hero-title {
      font-size: 40px; } }
  @media only screen and (min-width: 1020px) {
    .hero-title {
      font-size: 60px; } }

.h0 {
  color: #1c2036;
  font-family: "Aleo", serif;
  letter-spacing: 0.83px;
  line-height: 1.2; }
  @media only screen and (min-width: 1020px) {
    .h0 {
      font-size: 50px; } }
  @media only screen and (max-width: 1019px) {
    .h0 {
      font-size: 40px; } }

.h1 {
  color: #1c2036;
  font-family: "Aleo", serif;
  letter-spacing: 0.7px;
  line-height: 1.4; }
  @media only screen and (max-width: 1019px) {
    .h1 {
      font-size: 30px; } }
  @media only screen and (min-width: 1020px) {
    .h1 {
      font-size: 35px; } }

.h2 {
  color: #1c2036;
  font: normal 29px/1.24 "Aleo", serif;
  letter-spacing: 0.4px; }
  @media only screen and (max-width: 1019px) {
    .h2 span {
      display: block; } }

.h3 {
  color: #31354f;
  font: normal 26px/1.4 "Aleo", serif; }

.h4 {
  color: #4e526b;
  font: normal 24px/1.4 "Aleo", serif; }

.h5 {
  color: #4e526b;
  font: normal 20px/1.5 "Aleo", serif; }

.h6 {
  color: #31354f;
  font: normal 18px/1.5 "Aleo", serif;
  letter-spacing: 0.24px; }

.h7 {
  color: #31354f;
  font: normal 16px/1.5 "Aleo", serif;
  letter-spacing: 0.2px; }

.p {
  color: #4e526b;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5; }

.p__big {
  font-size: 18px; }

.p__small {
  color: #797c91;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.71; }

.p__tiny {
  color: #797c91;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.5; }

.p__title {
  color: #abacb8;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.15;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase; }

.p__darker {
  color: #4e526b; }

.p__gray {
  color: rgba(255, 255, 255, 0.6); }

.sub-p {
  margin-left: 3em; }
  @media only screen and (max-width: 1019px) {
    .sub-p {
      margin-left: 2em; } }

.strong {
  font-weight: bold; }

.italic {
  font-style: italic; }

.a {
  color: #24a652;
  transition: color 300ms ease;
  cursor: pointer; }
  .a:hover {
    color: #27b659; }
  .a:active {
    color: #a4e4ba; }

.a__type1 {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.86px;
  text-transform: uppercase; }

.a__gray {
  color: rgba(255, 255, 255, 0.6); }
  .a__gray:hover {
    color: rgba(255, 255, 255, 0.6); }
  .a__gray:active {
    color: rgba(255, 255, 255, 0.6); }

.a__underline, .underline {
  text-decoration: underline; }

.a__arrow {
  padding-right: 13px;
  line-height: 18px;
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .a__arrow .icon-arrow-right {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  .a__arrow > span {
    display: inline-block;
    vertical-align: top; }

.ul {
  margin-top: 20px; }
  .ul > li {
    padding-left: 30px;
    color: #4e526b;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    position: relative; }
    .ul > li:before {
      content: '';
      background: #4e526b;
      display: block;
      position: absolute;
      top: 9px;
      left: 9px;
      width: 4px;
      height: 4px;
      border-radius: 50%; }
    .ul > li + li {
      margin-top: 10px; }

.ol {
  counter-reset: li; }
  .ol > li {
    color: #31354f;
    font-size: 18px;
    line-height: 1.5; }
    .ol > li:first-child {
      margin-top: 20px; }
    .ol > li + li {
      margin-top: 28px; }
    .ol > li:before {
      content: counter(li) ". ";
      counter-increment: li;
      color: inherit;
      font-size: inherit;
      line-height: inherit; }

.title-holder {
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 50px;
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .title-holder {
      padding-top: 0;
      padding-bottom: 40px; } }

.tag {
  background: #0084ff;
  margin-left: 6px;
  margin-right: 6px;
  padding: 5px 23px;
  border-radius: 22px;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 0.1px;
  color: #fff;
  display: inline-block; }

.hero-title__custom,
.h0__custom,
.h1__custom,
.h2__custom,
.h3__custom,
.h4__custom,
.h5__custom,
.h6__custom,
.h7__custom,
.p__custom,
.ul__custom > li {
  color: #fff; }
  .hero-title__custom:before,
  .h0__custom:before,
  .h1__custom:before,
  .h2__custom:before,
  .h3__custom:before,
  .h4__custom:before,
  .h5__custom:before,
  .h6__custom:before,
  .h7__custom:before,
  .p__custom:before,
  .ul__custom > li:before {
    background: #fff; }

.error-tooltip {
  background: #fa1d47;
  width: 335px;
  padding: 10px 16px 8px;
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.43;
  border-radius: 8px;
  text-align: center;
  position: relative; }
  .error-tooltip:after {
    content: '';
    display: block;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    z-index: 2;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fa1d47;
    border-bottom: none;
    bottom: -6px;
    left: 50%; }

.icon-check {
  width: 18px;
  height: 18px;
  display: block;
  fill: #fff; }

.icon-checkbox {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  opacity: 0;
  fill: #fff;
  transition: opacity 300ms ease;
  pointer-events: none; }

.icon-attach {
  width: 20px;
  height: 18px;
  display: block; }

.icon-scroll-down-arrow {
  width: 24px;
  height: 24px;
  display: block; }

.nav {
  width: 70px;
  height: 70px;
  position: relative; }
  .nav .icon-nav {
    position: absolute;
    top: 0;
    left: 0; }
    .nav .icon-nav:first-child {
      opacity: 1; }
    .nav .icon-nav:last-child {
      opacity: 0; }
    .nav .icon-nav:hover:first-child {
      opacity: 0; }
    .nav .icon-nav:hover:last-child {
      opacity: 1; }

.icon-nav {
  width: 70px;
  height: 70px;
  display: block;
  cursor: pointer;
  transition: opacity 300ms ease; }
  .icon-nav.icon-nav__disabled {
    cursor: default; }

.icon-arrow {
  width: 16px;
  height: 72px;
  display: block;
  cursor: pointer;
  fill: #24a652; }
  .icon-arrow.icon-arrow__disabled {
    fill: #abacb8;
    cursor: default; }

.icon-star-26 {
  width: 26px;
  height: 26px;
  display: inline-block;
  vertical-align: top; }
  .icon-star-26 + .icon-star-26 {
    margin-left: 4px; }

.icon-star-20 {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top; }
  .icon-star-20 + .icon-star-20 {
    margin-left: 4px; }

.icon-play {
  background: #fff;
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
  fill: #24a652; }

.icon-papers {
  width: 12px;
  height: 15px;
  display: block; }

.icon-hat {
  width: 17px;
  height: 11px;
  display: block; }

.icon-check {
  width: 12px;
  height: 12px;
  display: block; }

.icon-twitter {
  width: 23px;
  height: 18px;
  display: block;
  fill: #fff; }

.icon-play,
.icon-replay {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 0 0; }
  .icon-play:hover,
  .icon-replay:hover {
    background-color: #d2d6d3; }

.icon-play {
  background-image: url("./../img/play.svg"); }

.icon-replay {
  background-image: url("./../img/replay.svg"); }

.icon-logo {
  width: 72px;
  height: 25px;
  display: block; }

.icon-arrow-right {
  width: 6px;
  height: 10px;
  display: block; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fixed {
  height: 100vh;
  overflow-y: hidden;
  padding-right: 15px; }

.overflow-x {
  overflow-x: hidden; }
