@media only screen and (max-width: 1019px) {
  .devices-block .holder {
    padding: 33px 22px 30px; } }

@media only screen and (min-width: 1020px) {
  .devices-block .holder {
    padding: 55px 90px 20px; } }

.devices-block .devices-block-inner {
  position: relative;
  border-radius: 6px;
  border: 1px solid #d5d6de;
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .devices-block .devices-block-inner {
      padding: 0 15px 16px; } }
  @media only screen and (min-width: 1020px) {
    .devices-block .devices-block-inner {
      padding: 16px 200px 15px 30px; } }

@media only screen and (max-width: 1019px) {
  .devices-block .svg-icon {
    width: 137px;
    height: 86px;
    margin-top: -33px; } }

@media only screen and (min-width: 1020px) {
  .devices-block .svg-icon {
    position: absolute;
    bottom: 9px;
    right: 40px;
    width: 136px;
    height: 84px; } }

@media only screen and (max-width: 1019px) {
  .devices-block .devices-msg {
    margin-top: 16px; } }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
