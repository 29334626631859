.our-prices .our-prices-wrapper {
  border-radius: 15px;
  background-color: #f7f8fa; }
  @media only screen and (max-width: 1019px) {
    .our-prices .our-prices-wrapper {
      text-align: center;
      padding: 23px 25px 32px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices .our-prices-wrapper {
      padding: 33px 130px 32px 84px;
      display: flex;
      justify-content: space-between;
      align-items: center; } }

@media only screen and (min-width: 1020px) {
  .our-prices .our-prices-msg-block {
    width: 420px; } }

@media only screen and (max-width: 1019px) {
  .our-prices .our-prices-title {
    margin-bottom: 20px; } }

@media only screen and (min-width: 1020px) {
  .our-prices .our-prices-title {
    margin-bottom: 24px; } }

.our-prices .our-prices-msg {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .our-prices .our-prices-msg {
      font-size: 18px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices .our-prices-msg {
      font-size: 16px; } }

.our-prices .our-prices-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.79;
  color: #24a652; }
  @media only screen and (max-width: 1019px) {
    .our-prices .our-prices-subtitle {
      margin: 16px 0; } }
  @media only screen and (min-width: 1020px) {
    .our-prices .our-prices-subtitle {
      margin: 32px 0 4px; } }

@media only screen and (max-width: 1019px) {
  .our-prices .our-prices-img {
    margin-top: 40px; } }

@media only screen and (max-width: 1019px) {
  .our-prices.our-prices_2 .our-prices-wrapper, .our-prices.our-prices_2_1 .our-prices-wrapper, .our-prices.our-prices_2_2 .our-prices-wrapper {
    padding: 23px 24px 40px; } }

@media only screen and (min-width: 1020px) {
  .our-prices.our-prices_2 .our-prices-wrapper, .our-prices.our-prices_2_1 .our-prices-wrapper, .our-prices.our-prices_2_2 .our-prices-wrapper {
    position: relative; } }

@media only screen and (max-width: 1019px) {
  .our-prices.our-prices_2 .our-prices-img, .our-prices.our-prices_2_1 .our-prices-img, .our-prices.our-prices_2_2 .our-prices-img {
    width: 260px;
    height: 256px; } }

@media only screen and (min-width: 1020px) {
  .our-prices.our-prices_2 .our-prices-img, .our-prices.our-prices_2_1 .our-prices-img, .our-prices.our-prices_2_2 .our-prices-img {
    position: absolute;
    right: 100px;
    bottom: 0;
    width: 325.1px;
    height: 320px; } }

@media only screen and (max-width: 1019px) {
  .our-prices.our-prices_2 .our-prices-subtitle, .our-prices.our-prices_2_1 .our-prices-subtitle, .our-prices.our-prices_2_2 .our-prices-subtitle {
    margin-top: 36px; } }

.our-prices.our-prices_2_1 .our-prices-subtitle {
  color: #225ee8; }

.our-prices.our-prices_2_2 .our-prices-subtitle {
  color: #7d3b17; }

.our-prices-block.theme_1 .our-prices-wrapper {
  background-color: #c2f2d1; }

@media only screen and (max-width: 1019px) {
  .our-prices-block {
    margin-top: 40px;
    margin-bottom: 60px; } }

@media only screen and (min-width: 1020px) {
  .our-prices-block {
    margin-top: 80px;
    margin-bottom: 80px; } }

@media only screen and (max-width: 1019px) {
  .our-prices-block .holder {
    padding-left: 16px;
    padding-right: 16px; } }

.our-prices-block .our-prices-wrapper {
  border-radius: 15px;
  background-color: #f7f8fa; }
  @media only screen and (max-width: 1019px) {
    .our-prices-block .our-prices-wrapper {
      padding: 28px 24px;
      text-align: center; } }
  @media only screen and (min-width: 1020px) {
    .our-prices-block .our-prices-wrapper {
      padding: 28px 100px 25px 46px;
      display: flex;
      justify-content: flex-start;
      align-items: center; } }

@media only screen and (max-width: 1019px) {
  .our-prices-block .our-prices-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.our-prices-block .title {
  font-family: "Aleo", serif;
  font-size: 24px;
  line-height: 1.52;
  letter-spacing: 0.33px;
  color: #4e526b; }

.our-prices-block .subtitle {
  font-family: "Aleo", serif;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.27px;
  color: #24a652; }
  @media only screen and (max-width: 1019px) {
    .our-prices-block .subtitle {
      margin-top: 9px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices-block .subtitle {
      margin-top: 12px; } }

.our-prices-block .our-price-msg1 {
  margin-top: 11px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #4e526b; }

.our-prices-block .our-price-msg2 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #797c91; }
  @media only screen and (max-width: 1019px) {
    .our-prices-block .our-price-msg2 {
      margin-top: 14px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices-block .our-price-msg2 {
      margin-top: 19px; } }
  .our-prices-block .our-price-msg2 .link {
    text-decoration: underline;
    color: #797c91; }

.our-prices-block .satisfaction-img {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .our-prices-block .satisfaction-img {
      width: 120px;
      height: 120px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices-block .satisfaction-img {
      flex: none;
      width: 200px;
      height: 200px;
      margin-left: 89px; } }

.our-prices_clp3 .holder {
  font-family: 'Axiforma',sans-serif;
  position: relative;
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .our-prices_clp3 .holder {
      margin-top: 35px;
      padding: 0 20px 40px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices_clp3 .holder {
      margin-top: 10px;
      padding-bottom: 38px; } }
  .our-prices_clp3 .holder:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 244px;
    margin-left: -122px;
    height: 1px;
    background-color: #dfe3e7; }

.our-prices_clp3 .our-prices-title {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.36px;
  color: #023135; }

.our-prices_clp3 .our-prices-msg {
  color: #131415; }
  @media only screen and (max-width: 1019px) {
    .our-prices_clp3 .our-prices-msg {
      padding-top: 16px;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.22px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices_clp3 .our-prices-msg {
      padding: 16px 169px 0;
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: 0.19px; } }

.our-prices_clp3 .our-prices-subtitle {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.22px;
  color: #007a5c; }
  @media only screen and (max-width: 1019px) {
    .our-prices_clp3 .our-prices-subtitle {
      padding: 28px 0 16px; } }
  @media only screen and (min-width: 1020px) {
    .our-prices_clp3 .our-prices-subtitle {
      padding: 16px 0; } }

.our-prices_clp3 .our-prices-img {
  display: block;
  width: 90px;
  height: 80px;
  margin: 0 auto; }

@media only screen and (max-width: 1019px) {
  .our-prices_clp3.type2 .holder {
    margin-top: 100px; } }

@media only screen and (min-width: 1020px) {
  .our-prices_clp3.type2 .holder {
    margin-top: 120px; } }
