.discount-block .holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media only screen and (max-width: 1019px) {
    .discount-block .holder {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media only screen and (min-width: 1020px) {
    .discount-block .holder {
      padding-top: 50px;
      padding-bottom: 45px; } }

.discount-block .discount-img {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .discount-block .discount-img {
      width: 69px;
      height: 61px; } }
  @media only screen and (min-width: 1020px) {
    .discount-block .discount-img {
      width: 56.6px;
      height: 50px; } }

.discount-block .discount-msg {
  font-family: "Aleo", serif;
  font-size: 26px;
  line-height: 1.42;
  letter-spacing: 0.3px;
  color: #4e526b;
  margin-top: 10px;
  text-align: center; }
  .discount-block .discount-msg .highlighted {
    font-weight: bold;
    color: #ff7d2b; }
  .discount-block .discount-msg .highlighted2 {
    color: #ff7d2b; }

.discount-block .discount-msg2 {
  font-family: "Aleo", serif;
  font-size: 26px;
  line-height: 1.42;
  letter-spacing: 0.3px;
  color: #31354f;
  margin-top: 10px;
  text-align: center; }
  .discount-block .discount-msg2 .highlighted {
    color: #ff7d2b; }

/*
  @include respond-to(handhelds) {
  }
  @include respond-to(wide-screens) {
  }
*/
