.attachBlock {
  min-height: 25px; }
  .attachBlock label {
    padding-left: 28px;
    color: #24a652;
    font-size: 16px;
    line-height: 25px;
    font-weight: bold;
    display: inline-block;
    vertical-align: top;
    position: relative;
    cursor: pointer; }
  .attachBlock .icon-attach {
    position: absolute;
    top: 2px;
    left: 0; }
  .attachBlock input {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .attachBlock + .p {
    margin-top: 8px;
    display: block; }
    @media only screen and (max-width: 1019px) {
      .attachBlock + .p {
        margin-top: 20px; } }

.attachBlock-progress {
  padding-right: 30px;
  position: relative; }

.attachBlock-progress-bar {
  background: #24a652;
  width: 0;
  height: 9px;
  margin-top: 5px;
  opacity: 0.47;
  border-radius: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative; }
  .attachBlock-progress-bar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.47) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.47) 50%, rgba(255, 255, 255, 0.47) 75%, transparent 75%, transparent);
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-radius: 10px;
    overflow: hidden; }

.attachBlock-progress-remove {
  background: #fa1d47;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  overflow: hidden; }
  .attachBlock-progress-remove:before, .attachBlock-progress-remove:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 50%;
    background: #fff; }
  .attachBlock-progress-remove:before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .attachBlock-progress-remove:after {
    transform: translate(-50%, -50%) rotate(-45deg); }
  .attachBlock-file .attachBlock-progress-remove {
    margin-left: 12px;
    margin-top: 3px;
    top: auto;
    right: auto;
    display: inline-block;
    vertical-align: top;
    position: relative; }

.attachBlock-file > span {
  display: inline-block;
  vertical-align: top; }
