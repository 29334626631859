.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50;
  transition: background 1s ease;
  background-color: rgba(28, 32, 54, 0.5); }

.modal__body {
  position: relative;
  width: 439px;
  background-color: #fff;
  border-radius: 3px;
  text-align: center;
  padding: 32px 20px 20px;
  box-sizing: border-box;
  opacity: 1;
  z-index: 51; }

.modal .h3 {
  margin-bottom: 24px; }

.modal .h6 {
  margin-bottom: 24px; }

.modal__body {
  width: 335px; }
  .modal__body .button {
    width: 100%; }
  .modal__body .icon-logo {
    margin: 0 auto 20px auto; }

.modal-image {
  margin-bottom: 27px;
  margin-left: auto;
  margin-right: auto;
  display: block; }

.modal-image__thumb {
  width: 81px;
  height: 80px; }

.modal-image__clock {
  width: 92px;
  height: 70px; }

.modal-link {
  margin-top: 16px; }
  .modal-link + .button {
    margin-top: 20px; }

.modal__locale-change .input {
  margin-top: 30px; }

.modal__locale-change .button {
  margin-top: 20px; }

.modal__get-off {
  color: #ff7d2b; }

@media only screen and (max-width: 1019px) {
  .modal-document-preview .modal__body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    background-color: #1c2036;
    border-radius: 0;
    padding: 15px 15px 25px;
    transform: none;
    width: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column; } }

@media only screen and (min-width: 1020px) {
  .modal-document-preview .modal__body {
    padding: 0 0 30px;
    width: 460px;
    border-radius: 6px;
    max-height: 100vh; } }

@media only screen and (max-width: 1019px) {
  .modal-document-preview .modal-preview-img-wrapper {
    background-color: #fff;
    flex-grow: 1; } }

@media only screen and (min-width: 1020px) {
  .modal-document-preview .modal-preview-img-wrapper {
    min-height: 595px;
    max-height: 600px; } }

.modal-document-preview .modal-preview-img {
  display: block;
  width: 100%; }
  @media only screen and (max-width: 1019px) {
    .modal-document-preview .modal-preview-img {
      height: auto; } }
  @media only screen and (min-width: 1020px) {
    .modal-document-preview .modal-preview-img {
      border-radius: 6px;
      max-height: 600px; } }

@media only screen and (max-width: 1019px) {
  .modal-document-preview .modal-preview-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px; } }

@media only screen and (min-width: 1020px) {
  .modal-document-preview .modal-preview-close-btn {
    position: absolute;
    right: -16px;
    top: -16px; } }

@media only screen and (max-width: 1019px) {
  .modal-document-preview .close-icon {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .modal-document-preview .close-icon {
    display: block;
    width: 32px;
    height: 32px; } }

@media only screen and (max-width: 1019px) {
  .modal-document-preview .close-icon2 {
    display: block;
    width: 50px;
    height: 50px; } }

@media only screen and (min-width: 1020px) {
  .modal-document-preview .close-icon2 {
    display: none; } }
