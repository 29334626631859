.readMoreText {
  overflow: hidden;
  position: relative;
  text-align: left; }
  .readMoreText.collapsed::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 90%);
    height: 50px; }
