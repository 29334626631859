/* Slider */
/*
.slick-slider
{
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list
{
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus
{
  outline: none;
}
.slick-list.dragging
{
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track
{
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after
{
  display: table;

  content: '';
}
.slick-track:after
{
  clear: both;
}
.slick-loading .slick-track
{
  visibility: hidden;
}

.slick-slide
{
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide
{
  float: right;
}
.slick-slide img
{
  display: block;
}
.slick-slide.slick-loading img
{
  display: none;
}
.slick-slide.dragging img
{
  pointer-events: none;
}
.slick-initialized .slick-slide
{
  display: block;
}
.slick-loading .slick-slide
{
  visibility: hidden;
}
.slick-vertical .slick-slide
{
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
*/
@media only screen and (max-width: 1019px) {
  .slick-slider2 {
    min-width: 375px; } }

@media only screen and (max-width: 1019px) {
  .slick-slider2 .holder {
    padding-top: 22px;
    padding-bottom: 30px;
    width: auto; } }

@media only screen and (min-width: 1020px) {
  .slick-slider2 .slick-track {
    /*
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      */ } }

.slick-slider2 .slick-title {
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .slick-slider2 .slick-title {
      margin-bottom: 45px;
      padding: 0 25px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider2 .slick-title {
      margin-bottom: 35px; } }

@media only screen and (min-width: 1020px) {
  .slick-slider2 .slick-slide:not(:first-child) {
    margin-left: 56px; } }

.slick-slider2 .slick-item {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 300px;
  border-radius: 15px;
  background-color: #c2f2d1;
  padding-top: 18px; }
  @media only screen and (max-width: 1019px) {
    .slick-slider2 .slick-item {
      width: 250px !important;
      margin-left: 20px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider2 .slick-item {
      width: 290px !important; } }

.slick-slider2 .item-tag {
  height: 41px;
  border-radius: 20.5px;
  background-color: #fff;
  padding: 0 24px;
  display: inline-flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #4e526b; }

.slick-slider2 .item-title {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.5;
  color: #4e526b;
  margin-top: 25px;
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .slick-slider2 .item-title {
      font-size: 18px; } }
  @media only screen and (min-width: 1020px) {
    .slick-slider2 .item-title {
      font-size: 16px; } }

.slick-slider2 .item-img {
  margin-top: auto;
  display: block; }

.slick-slider2 .popular-task1 {
  width: 135px;
  height: 142px;
  background: url("./img/popular-task1.svg") no-repeat; }

.slick-slider2 .popular-task2 {
  width: 138px;
  height: 134px;
  background: url("./img/popular-task2.svg") no-repeat;
  margin-left: -10px; }

.slick-slider2 .popular-task3 {
  width: 155px;
  height: 137px;
  background: url("./img/popular-task3.svg") no-repeat;
  margin-right: -10px; }

.slick-slider2 .slick-arrow {
  display: none !important; }

@media only screen and (max-width: 1019px) {
  .slick-slider2 .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 40px; } }

@media only screen and (min-width: 1020px) {
  .slick-slider2 .slick-dots {
    display: none !important; } }

@media only screen and (max-width: 1019px) {
  .slick-slider2 li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 9px;
    background-color: #dfdfdf;
    transition: background .3s ease;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; } }

.slick-slider2 li.slick-active button {
  background-color: #1c2036; }

@media only screen and (min-width: 1020px) {
  .slick-slider2 .slick-static {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
